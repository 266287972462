export default {
    iframeListener() {
        document.querySelectorAll('.iframeable').forEach(el => {
            var iframes = el.querySelectorAll('figure iframe');
            if (iframes) {
                for (let i = 0; i < iframes.length; i++) {
                    var iframeParent = iframes[i].parentElement;
                    var iframeHeight = iframes[i].clientHeight;
                    var iframeWidth = iframes[i].clientWidth;
                    if (iframeWidth > iframeHeight) {
                        var paddingTop = iframes[i].clientHeight / iframes[i].clientWidth * 100;
                    } else {
                        var paddingTop = iframes[i].clientWidth / iframes[i].clientHeight * 100;
                    }
                    if (paddingTop < 5) {
                        paddingTop = 100;
                    }
                    iframeParent.style.cssText = 'position: relative; overflow: hidden; max-width: 100%; padding:0; padding-top: ' + paddingTop + '%;';
                    iframes[i].style.cssText = 'position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: 0;';
                }
            }
        });
    }
};